import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, keywords, image, pathname }) => {
  const siteUrl = "https://www.jmrehabclinic.ca";
  const defaultDescription = "Leading physiotherapy and RMT services in Surrey, BC. Experienced physiotherapist and registered massage therapist offering IMS, dry needling, myo-fascial release, hot stone massage, cold stone massage and rehabilitation. Direct billing available. Book today!";
  const defaultTitle = "Physiotherapy & Massage Therapy Surrey BC | JM Rehab Clinic";
  const defaultImage = `${siteUrl}/images/logo.png`;
  const defaultKeywords = "rmt surrey, massage therapy surrey, physiotherapy surrey,best physio Surrey, best RMT Surrey, budget friendly physio Surrey, budget friendly RMT Surrey, RMT Surrey, massage therapy Surrey, IMS therapy, dry needling Surrey, vestibular therapy Surrey, hot stone massage surrey, cold stone massage surrey";

  const currentUrl = `${siteUrl}${pathname || ''}`;

  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      <link rel="canonical" href={currentUrl} />
      
      {/* Primary Schema.org markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "MedicalBusiness",
          "name": "JM Rehab Clinic",
          "alternateName": [
            "Surrey Physiotherapy & RMT Clinic",
            "JM Physio and Massage",
            "Best Physiotherapy Clinic Surrey",
            "Best Massage Therapy",
            "Affordable RMT Surrey",
            "Budget-Friendly Physio Surrey",
            "Budget-Friendly RMT Surrey",
            "Top-Rated Massage Therapy Surrey"
          ],
          "image": defaultImage,
          "url": siteUrl,
          "telephone": "+1-778-956-9061",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Unit D 6225 136st Surrey, BC",
            "addressLocality": "Surrey",
            "addressRegion": "BC",
            "postalCode": "V3X 1H3",
            "addressCountry": "CA"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "49.1829",
            "longitude": "-122.8524"
          },
          "priceRange": "$85-$145",
          "areaServed": ["Surrey", "White Rock", "Delta", "Langley"],
          "medicalSpecialty": [
            "Physiotherapy",
            "Registered Massage Therapy",
            "Functional Dry Needling",
            "Vestibular Therapy",
            "IMS Treatment",
            "Rehabilitation",
            "Shoulder Assessment",
            "Active Rehab",
            "Neuro Rehab",
            "Post Surgery Rehabilitation",
            "Manual Therapy",
            "Therapuetic exercices"
          ],
          "employee": [
            {
              "@type": "Person",
              "name": "Wren Paul Meyn",
              "jobTitle": "Physiotherapist",
              "description": "Experienced physiotherapist with extensive background in rehabilitation and manual therapy"
            },
            {
              "@type": "Person",
              "name": "Jisha Jacob",
              "jobTitle": "Registered Massage Therapist",
              "description": "Experienced RMT in therapeutic massage and rehabilitation, pain relief, injury prevention, manipulations and mobilization of other manual methods"
            }
          ],
          "publicAccess": false,
          "isAccessibleForFree": false,
          "requiresBooking": true,
          "availableService": [
            {
              "@type": "MedicalTherapy",
              "name": "Physiotherapy",
              "description": "Experienced physiotherapy including IMS, dry needling, and rehabilitation. Budget-friendly rates with direct billing available."
            },
            {
              "@type": "MedicalTherapy",
              "name": "Registered Massage Therapy",
              "description": "Professional massage therapy services including deep tissue and therapeutic massage. Affordable rates with direct billing."
            },
            {
              "@type": "Service",
              "name": "Physiotherapy and RMT Services",
              "description": "By appointment only. No walk-ins accepted. Direct billing available."
            }
          ]
        })}
      </script>

      {/* FAQ Schema */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What are your physiotherapy rates in Surrey?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer budget-friendly physiotherapy services starting from $95 for a 30-minute session. Initial assessments are $110 for 45 minutes. We provide direct billing for your convenience."
                }
              },
              {
                "@type": "Question",
                "name": "How much does RMT cost at your Surrey clinic?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our affordable RMT services start at $85 - $130 + GST. We offer competitive rates with direct billing options to make massage therapy accessible to everyone."
                }
              },
              {
                "@type": "Question",
                "name": "Why are you considered one of the best physiotherapy clinics in Surrey?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We're known for our experienced therapists, personalized care, competitive rates, and comprehensive treatment options including IMS, dry needling, and vestibular therapy. Our focus on patient outcomes and affordable pricing makes us a top choice in Surrey."
                }
              },
              {
                "@type": "Question",
                "name": "What makes your RMT services among the best in Surrey?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our registered massage therapist is highly skilled professional offering quality therapeutic massage at budget-friendly rates. We provide personalized treatment plans and accept direct billing with major insurance providers."
                }
              },
              {
                "@type": "Question",
                "name": "What services do you offer at your Surrey clinic?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer both physiotherapy and registered massage therapy (RMT) services. Our treatments include IMS/dry needling, shoulder assessment, manual therapy, neuro rehab, vestibular therapy, active rehab, and more."
                }
              },
              {
                "@type": "Question",
                "name": "Do you offer direct billing for both physiotherapy and RMT services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we offer direct billing with all major insurance providers for both physiotherapy and massage therapy services."
                }
              },
              {
                "@type": "Question",
                "name": "What are your massage therapy (RMT) rates in Surrey?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our RMT services range from $85 (30 minutes), $120 (45 minutes) to $130 (60 minutes). All treatments are provided by registered massage therapist."
                }
              },
              {
                "@type": "Question",
                "name": "What conditions do your physiotherapists treat?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our physiotherapists treat various conditions including sports injuries, chronic pain, acute injuries, post-surgical rehabilitation, orthopedic conditions, neurological conditions and vestibular conditions using evidence-based techniques."
                }
              },
              {
                "@type": "Question",
                "name": "Do you accept walk-ins at your Surrey clinic?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No, we do not accept walk-ins. Prior appointment is required for all services. Please call or text to book your appointment."
                }
              },
              {
                "@type": "Question",
                "name": "How can I book an appointment?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can book an appointment by calling or texting our clinic. We do not offer online booking at this time. All services are by appointment only."
                }
              }
            ]
          }
        `}
      </script>

      {/* Service-specific Schema */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "MedicalTherapy",
            "name": "Surrey Physiotherapy and Massage Therapy",
            "alternateName": ["Surrey Physiotherapy & RMT Clinic", "JM Physio and Massage", JM Rehab","Best Physiotherapy Clinic Surrey",
              "RMT Surrey",
              "Budget-Friendly RMT Surrey",
              "Budget-Friendly Physio Surrey",
              "Top-Rated Massage Therapy Surrey"],
            "description": "Professional physiotherapy and RMT services in Surrey, BC. Direct billing available for both services.",
            "medicineSystem": "Physical Therapy and Massage Therapy",
            "relevantSpecialty": ["Physical Medicine", "Massage Therapy"],
            "serviceType": ["Physiotherapy", "Registered Massage Therapy", "Functional Dry Needling", "Vestibular Therapy", "IMS Treatment", "Rehabilitation"],
            "paymentAccepted": ["Credit Card", "Debit Card", "Insurance", "Direct Billing"],
            "study": [
              {
                "@type": "MedicalStudy",
                "healthCondition": [
                  "Sports Injuries",
                  "Chronic Pain",
                  "Muscle Tension",
                  "Post-surgical Rehabilitation",
                  "Stress Relief"
                ]
              }
            ]
          }
        `}
      </script>

      {/* Enhanced Service-specific Schema for all specialties */}
      <script type="application/ld+json">
        {`
          [
            {
              "@context": "https://schema.org",
              "@type": "MedicalTherapy",
              "name": "Physiotherapy Surrey",
              "description": "Experienced physiotherapy services in Surrey, BC. Evidence-based treatments, direct billing available. Specializing in manual therapy, exercise prescription, and rehabilitation.",
              "medicineSystem": "Physical Therapy",
              "relevantSpecialty": "Physical Medicine",
              "healthCondition": [
                "Sports Injuries",
                "Chronic Pain",
                "Post-surgical Recovery",
                "Musculoskeletal Conditions",
                "Arthritic Joint Condition",
                "Frozen Shoulder",
                "Spondylosis",
                "Back Pain",
                "Neck Pain",
                "Tennis Elbow",
                "Foot pain",
                "Plantar Fascitis",
                "Fractures",
              ]
            },
            {
              "@context": "https://schema.org",
              "@type": "MedicalTherapy",
              "name": "Registered Massage Therapy Surrey",
              "description": "Professional RMT services in Surrey. Therapeutic massage, deep tissue massage, and injury treatment. Direct billing available.",
              "medicineSystem": "Massage Therapy",
              "relevantSpecialty": "Therapeutic Massage",
              "healthCondition": [
                "Muscle Tension",
                "Stress Relief",
                "Sports Recovery",
                "Chronic Pain Management",
              ]
            },
            {
              "@context": "https://schema.org",
              "@type": "MedicalTherapy",
              "name": "Functional Dry Needling Surrey",
              "description": "Experienced functional dry needling treatment in Surrey. Specialized technique for muscle pain, tension, and trigger point release.",
              "medicineSystem": "Physical Medicine",
              "relevantSpecialty": "Pain Management",
              "healthCondition": [
                "Myofascial Pain",
                "Trigger Points",
                "Muscle Tension",
                "Athletic Performance",
                "Movement Dysfunction",
                "Chronic Pain"
              ]
            },
            {
              "@context": "https://schema.org",
              "@type": "MedicalTherapy",
              "name": "Vestibular Therapy Surrey",
              "description": "Specialized vestibular rehabilitation in Surrey. Treatment for dizziness, vertigo, and balance disorders.",
              "medicineSystem": "Physical Medicine",
              "relevantSpecialty": "Vestibular Rehabilitation",
              "healthCondition": [
                "Vertigo",
                "Dizziness",
                "Balance Disorders",
                "BPPV",
                "Post-Concussion Symptoms",
                "Vestibular Neuritis"
              ]
            },
            {
              "@context": "https://schema.org",
              "@type": "MedicalTherapy",
              "name": "IMS/Dry Needling Treatment Surrey",
              "description": "Specialized IMS (Intramuscular Stimulation) treatment in Surrey. Experienced practitioners for muscle pain and tension relief.",
              "medicineSystem": "Physical Medicine",
              "relevantSpecialty": "Pain Management",
              "healthCondition": [
                "Muscle Pain",
                "Trigger Points",
                "Chronic Pain",
                "Athletic Injuries"
              ]
            },
            {
              "@context": "https://schema.org",
              "@type": "MedicalTherapy",
              "name": "Rehabilitation Services Surrey",
              "description": "Comprehensive rehabilitation programs in Surrey. Personalized treatment plans for injury recovery and prevention.",
              "medicineSystem": "Physical Medicine",
              "relevantSpecialty": "Rehabilitation",
              "healthCondition": [
                "Post-injury Recovery",
                "Sports Rehabilitation",
                "Post-surgical Rehabilitation",
                "Functional Recovery"
              ]
            }
          ]
        `}
      </script>

      {/* Enhanced FAQ Schema with additional services */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What is Functional Dry Needling and how can it help me?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Functional Dry Needling in Surrey is an effective treatment technique that uses thin needles to target trigger points and relieve muscle pain. It's particularly effective for chronic pain, sports injuries, and movement dysfunction."
                }
              },
              {
                "@type": "Question",
                "name": "What conditions can Vestibular Therapy treat?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our Vestibular Therapy in Surrey treats conditions like vertigo, dizziness, BPPV, balance disorders, and post-concussion symptoms. We provide specialized assessment and treatment plans for vestibular conditions."
                }
              },
              {
                "@type": "Question",
                "name": "What's the difference between IMS and Functional Dry Needling?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "While both techniques use needles to treat muscle pain, Functional Dry Needling focuses on improving movement patterns and muscle function, while IMS specifically targets nerve-related muscle pain and tension."
                }
              }
            ]
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "SpecialAnnouncement",
            "name": "Appointment Required Policy",
            "text": "Please note that all services at JM Rehab Clinic require prior appointment. We do not accept walk-ins.",
            "category": "https://schema.org/BusinessUpdate",
            "expires": "2025-12-31T23:59"
          }
        `}
      </script>

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={image || defaultImage} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={currentUrl} />
      <meta property="twitter:title" content={title || defaultTitle} />
      <meta property="twitter:description" content={description || defaultDescription} />
      <meta property="twitter:image" content={image || defaultImage} />
    </Helmet>
  );
};

export default SEO; 