import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';

const Services = () => {
  const services = {
    massage: {
      title: "Massage Therapy",
      image: "images/massage.jpg",
      description: "Professional massage therapy services focused on developing, maintaining, rehabilitating and augmenting physical function, relieving pain, and promoting overall functional health.",
      treatments: [
        "Assessment of soft tissue and joints of the body",
        "Myofascial Release - Addresses the fascia (connective tissue) to release restrictions and improve movement",
        "Trigger Point Therapy - Focuses on specific areas of muscle tightness or 'trigger points' to alleviate pain and discomfort",
        "Injury specific treatments for chronic sports injuries",
        "Hot and cold stone massage",
        "Personalized home exercise program tailored to individual needs"
      ],
      pricing: [
        { duration: "60 minutes", type: "Initial or Subsequent Treatment", price: 130 },
        { duration: "45 minutes", type: "Initial or Subsequent Treatment", price: 120 },
        { duration: "30 minutes", type: "Initial or Subsequent Treatment", price: 85 }
      ],
      note: "Please note that applicable GST incurs on massage therapy visit fee as massage therapy services are not GST exempt in British Columbia."
    },
    physio: {
      title: "Physiotherapy",
      image: "images/vision.jpg",
      description: "Evidence-based physiotherapy treatments tailored to your needs.",
      treatments: [
        "LASER therapy",
        "Ultrasound therapy",
        "IFC/TENS (Transcutaneous Electrical Nerve Stimulation)",
        "Functional dry needling",
        "Manual therapy and exercise prescription",
        "Shoulder assessment and treatment"
      ],
      pricing: [
        { duration: "45 minutes", type: "Initial Visit", price: 110 },
        { duration: "30 minutes", type: "Subsequent Visit", price: 95 },
        { duration: "45 minutes", type: "Subsequent Visit", price: 110 },
        { duration: "60 minutes", type: "Initial and Subsequent Visit", price: 145 },
        { duration: "30 minutes", type: "Shoulder Assessment", price: 90 },
        { duration: "60 minutes", type: "Initial Vestibular Therapy", price: 140 },
        { duration: "30 minutes", type: "Vestibular Therapy", price: 115 }
      ]
    }
  };

  return (
    <>
      <Helmet>
        <title>Our Services - JM Rehab Clinic | Surrey, BC</title>
        <meta name="description" content="Comprehensive physiotherapy and massage therapy services including IMS/Dry Needling, Vestibular Therapy, and more in Surrey, BC." />
      </Helmet>

      <SEO 
        title="Services & Pricing | Physiotherapy & Massage Therapy Services Surrey"
        description="Comprehensive physiotherapy and massage therapy services including myofascial release, trigger point therapy, laser therapy, and IMS/dry needling. Direct billing available. Prices range from $80-$145."
        keywords="physiotherapy services Surrey, massage therapy prices, direct billing, myofascial release, trigger point therapy, laser therapy, ultrasound therapy, TENS"
        pathname="/services"
      />

      <div className="pt-24 pb-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-12">Our Services</h1>

          {/* Services Sections */}
          {Object.values(services).map((service, index) => (
            <div key={index} className="mb-16">
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="md:flex">
                  <div className="md:w-1/2 h-[800px]">
                    <img 
                      src={service.image} 
                      alt={service.title} 
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="p-8 md:w-1/2">
                    <h2 className="text-2xl font-semibold mb-4">{service.title}</h2>
                    <p className="text-gray-600 mb-6">{service.description}</p>
                    
                    <h3 className="text-lg font-semibold mb-3">Session Features:</h3>
                    <ul className="list-disc pl-5 space-y-2 mb-8">
                      {service.treatments.map((treatment, idx) => (
                        <li key={idx} className="text-gray-600">{treatment}</li>
                      ))}
                    </ul>

                    {/* Pricing Table */}
                    <div className="bg-gray-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold mb-4">Pricing</h3>
                      <div className="space-y-3">
                        {service.pricing.map((price, idx) => (
                          <div key={idx} className="flex justify-between items-center border-b border-gray-200 pb-2">
                            <div>
                              <p className="font-medium">{price.duration}</p>
                              <p className="text-sm text-gray-600">{price.type}</p>
                            </div>
                            <p className="font-semibold">${price.price}</p>
                          </div>
                        ))}
                      </div>
                      {service.note && (
                        <p className="text-sm text-gray-500 mt-4 italic">
                          {service.note}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Call to Action */}
          <div className="text-center mt-12">
            <p className="text-xl text-gray-600 mb-6">
            Start your journey to optimal function and recovery?
            </p>
            <a 
              href="/contact" 
              className="inline-block bg-sage-green hover:bg-sage-green/90 text-white px-8 py-3 rounded-md transition-colors"
            >
              Book an Appointment
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services; 