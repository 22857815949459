import { Link } from 'react-router-dom';
import { 
  BuildingOffice2Icon, 
  PhoneIcon, 
  EnvelopeIcon 
} from '@heroicons/react/24/outline';

function Footer() {
  return (
    <footer className="bg-slate-blue text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <div className="space-y-2">
              <p>JM Rehab Clinic</p>
              <p>Unit D, 6225 - 136 Street</p>
              <p>Surrey, BC V3X 1H3</p>
              <div className="flex items-center mt-4">
                <BuildingOffice2Icon className="h-5 w-5 mr-2" />
                <span>Office: 604-503-9955</span>
              </div>
              <div className="flex items-center mt-2">
                <PhoneIcon className="h-5 w-5 mr-2" />
                <span>Cell: 778-956-9061</span>
              </div>
              <div className="flex items-center mt-2">
                <EnvelopeIcon className="h-5 w-5 mr-2" />
                <span>jmrehabclinic@gmail.com</span>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/services" className="hover:text-olive-gray hover:underline transition-colors">Our Services</Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-olive-gray hover:underline transition-colors">About Us</Link>
              </li>
              <li>
                <Link to="/direct-billing" className="hover:text-olive-gray hover:underline transition-colors">Direct Billing</Link>
              </li>
              <li>
                <Link to="/faq" className="hover:text-olive-gray hover:underline transition-colors">FAQ</Link>
              </li>
              <li>
                <Link to="/policies" className="hover:text-olive-gray hover:underline transition-colors">Clinic Policies</Link>
              </li>
            </ul>
          </div>

          {/* Hours of Operation */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Hours of Operation</h3>
            <div className="space-y-2">
              <p>Monday - Friday: 9:00 AM - 9:00 PM</p>
              <p>Saturday: 9:00 AM - 5:00 PM</p>
              <p>Sunday: Closed</p>
              <div className="mt-6">
                <a 
                  href="https://maps.google.com/?q=JM+Rehab+Clinic+Surrey+BC" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-block bg-sky-blue hover:bg-powder-blue px-4 py-2 rounded-md transition-colors"
                >
                  Get Directions
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 pt-8 border-t border-teal-700 text-center">
          <p>&copy; {new Date().getFullYear()} JM Rehab Clinic. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 