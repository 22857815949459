import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { 
  ExclamationTriangleIcon, 
  ShieldCheckIcon, 
  DocumentTextIcon,
  ChevronDownIcon,
  ChevronUpIcon 
} from '@heroicons/react/24/outline';

const Policies = () => {
  const [isDetailedPoliciesOpen, setIsDetailedPoliciesOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Clinic Policies - JM Rehab Clinic | Surrey, BC</title>
        <meta name="description" content="Learn about JM Rehab Clinic's policies, including cancellation, privacy, and insurance information." />
      </Helmet>

      <div className="pt-24 pb-12 bg-gray-50 relative">
        {/* Content Container */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-12">Clinic Policies</h1>

          {/* Cancellation Policy */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <div className="flex items-start mb-4">
              <ExclamationTriangleIcon className="h-6 w-6 text-sage-green mr-2 flex-shrink-0" />
              <h2 className="text-2xl font-semibold">Cancellation Policy</h2>
            </div>
            <div className="space-y-4 text-gray-600">
              <p>
                The clinic reserves a spot for you each time you book an appointment. If you miss an appointment, it's a loss incurred to the clinic.
              </p>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-medium text-gray-900 mb-2">Important Notes:</p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>The clinic at the moment does not allow online booking.</li>
                  <li>Patients may call clinic for their first appointments.</li>
                  <li>Follow-up appointments can be booked while in the clinic itself.</li>
                  <li>If you are late for your appointment on the day, please call and speak with the therapist.</li>
                  <li>If you cannot make it to your appointment, please call or text upfront.</li>
                </ul>
              </div>
              <div className="mt-4 p-4 bg-red-50 rounded-lg">
                <p className="text-red-700 font-medium">
                  No-show fees will be charged for missed appointments after the first occurrence
                </p>
              </div>
            </div>
          </div>

          {/* Privacy Policy */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <div className="flex items-start mb-4">
              <ShieldCheckIcon className="h-6 w-6 text-sage-green mr-2 flex-shrink-0" />
              <h2 className="text-2xl font-semibold">Privacy Policy</h2>
            </div>
            <div className="text-gray-600">
              <p>
                We adhere to PIPA (Personal Information Protection Act) Canada regulations for handling and protecting your personal information.
              </p>
              <ul className="list-disc pl-5 mt-4 space-y-2">
                <li>All patient information is kept strictly confidential</li>
                <li>Records are securely stored and protected</li>
                <li>Access to patient records is strictly controlled</li>
                <li>Information sharing requires written authorization and documented consent</li>
                <li>We comply with HIPAA (Health Insurance Portability and Accountability Act) and PIPEDA (Personal Information Protection and Electronic Documents Act) regulations</li>
              </ul>
            </div>
          </div>

          {/* Insurance Information */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-start mb-4">
              <DocumentTextIcon className="h-6 w-6 text-sage-green mr-2 flex-shrink-0" />
              <h2 className="text-2xl font-semibold">Insurance Information</h2>
            </div>
            <div className="text-gray-600">
              <p>
                We offer direct billing services with various insurance providers to streamline your treatment process and ensure convenient access to care.
              </p>
              <ul className="list-disc pl-5 mt-4 space-y-2">
                <li>Extended Health Benefits</li>
                <li>WorkSafeBC Claims</li>
                <li>MSP Premium Assistance</li>
              </ul>
              <p className="mt-4 italic">
                Please contact your insurance provider to verify your coverage details before your appointment.
              </p>
            </div>
          </div>

          {/* Detailed Policies Section */}
          <div className="bg-white rounded-lg shadow-md p-6 mt-8">
            <button
              onClick={() => setIsDetailedPoliciesOpen(!isDetailedPoliciesOpen)}
              className="w-full flex items-center justify-between text-left"
            >
              <div className="flex items-start">
                <DocumentTextIcon className="h-6 w-6 text-sage-green mr-2 flex-shrink-0" />
                <h2 className="text-2xl font-semibold">Detailed Policies and Protocols</h2>
              </div>
              {isDetailedPoliciesOpen ? (
                <ChevronUpIcon className="h-6 w-6 text-sage-green" />
              ) : (
                <ChevronDownIcon className="h-6 w-6 text-sage-green" />
              )}
            </button>

            {isDetailedPoliciesOpen && (
              <div className="mt-6 space-y-6">
                <p className="text-gray-600 italic">
                  Please read the following instructions/notices. Should you find that you are unable to comply with any of the clinic requirements or that of treatment, you are advised to cancel your appointment. No fee is charged for cancellations of your first appointment.
                </p>

                <div className="space-y-6">
                  <ol className="list-decimal pl-5 space-y-4">
                    <li className="text-gray-600">
                      Wearing a medical mask continues to be a recommendation in JM rehab clinic - while at the clinic and during the entire treatment time. Your treatments may require you to be in face up, face down or on side lying positions or even sitting. If you have difficulty wearing a mask and be in certain treatment positions, please discuss it with the RMT/PT and an alternate position can be agreed. The practitioners wear masks throughout the treatment time.
                    </li>
                    <li className="text-gray-600">
                      Upon arrival to the clinic, please use the hand sanitizer to sanitize your hands. It is also advised that you may sanitize your hands while leaving the clinic.
                    </li>
                    <li className="text-gray-600">
                      Make sure to fill up the covid-19 screening form that is emailed to you 24 hours before your appointment. Should you fail to do so, you will be asked to fill one while at the clinic.
                    </li>
                    <li className="text-gray-600">
                      Upon arrival you will be asked again about your health and covid-19 and other flu related questions.
                    </li>
                    <li className="text-gray-600">
                      Your appointment will be cancelled upon arrival should the clinic find that you fail your covid-19 screening criteria.
                      <br />
                      Please note: Initial massage therapy sessions (first visit only) include a comprehensive assessment and may extend beyond 60 minutes to ensure proper treatment planning.
                    </li>
                    <li className="text-gray-600">
                      You may arrive 10 to 15 minutes early for your first appointment for filling up some documents that are part of your clinic records, however; you may arrive no earlier than 3 to 5 minutes of your scheduled time for your subsequent visits.
                    </li>
                    <li className="text-gray-600">
                      Please note that the appointment scheduled consists of an assessment, treatment and a possible reassessment and home care explanation. Your appointment begins at the scheduled time and finishes at the time scheduled. However, Initial massage therapy sessions include a comprehensive assessment and may extend beyond 60 minutes to ensure proper treatment planning.
                    </li>
                    <li className="text-gray-600">
                      This intake form is part of your clinic records and it is kept confidential.
                    </li>
                    <li className="text-gray-600">
                      Please be aware that RMT/PT makes every effort to maintain cleanliness in the treatment room. Only fresh clean linen is used for each patient, after each treatment, the treatment table, face cradle and the handle and between and under it, lotion bottle, the chair, the door and the floor right below the face cradle are all sanitized using an approved sanitizer.
                    </li>
                    <li className="text-gray-600">
                      The RMT washes her hands before and after each treatment. If you have questions or concerns, please ask the RMT.
                    </li>
                    <li className="text-gray-600">
                      A formal informed consent will be taken during your appointment. This will be done in writing which requires your signature as well as done verbally during each visit you make.
                    </li>
                    <li className="text-gray-600">
                      The clinic visits may involve some risk of COVID-19 / flu transmissions, the RMT/PT is following a protocol to reduce or mitigate risk, but that risk cannot be reduced to zero.
                    </li>
                    <li className="text-gray-600">
                      You are advised to cancel your appointment, should you disagree with the clinic policies and protocols.
                    </li>
                    <li className="text-gray-600">
                      Preventing covid-19/flu transmissions is the responsibility of all citizens. Safety is a shared responsibility.
                    </li>
                  </ol>
                </div>

                {/* Massage Information */}
                <div className="mt-8">
                  <h3 className="text-xl font-semibold mb-4">Massage Treatment Information</h3>
                  <div className="text-gray-600 space-y-4">
                    <p>
                      RMT use organic coconut oil for massage. if you are allergic to organic coconut oil, please inform upfront. An alternate mineral massage oil can be used in that case. The clinic does not use scented oils for massage. no lotion is used either. The clinic does not encourage patients bringing their own massage oils as we are mindful of allergies of general population.
                    </p>
                    <p>
                      RMT has stone massage certification. Heated stones are used as a tool for massage in this case. If you are allergic to chlorine (similar to that of pools) please let the RMT know upfront. If you have questions about stone massage, please call the clinic and speak with the RMT.
                    </p>
                  </div>
                </div>

                {/* Parking Information */}
                <div className="mt-8">
                  <h3 className="text-xl font-semibold mb-4">Parking Information</h3>
                  <p className="text-gray-600">
                    Access to JM rehab clinic is from the back of the building. Unit D is located at the rear of surrey business center. There is ample parking space at the back of the building from where you can access the clinic front door. Please call the clinic if you need further parking or access instructions on the day of your appointment.
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Disclaimer Section */}
          <div className="bg-white rounded-lg shadow-md p-6 mt-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">Appointment Timing Notice</h3>
              <p className="text-gray-600">
                Your appointment duration begins when your name is called and ends when you exit the treatment room.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policies;