import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const FAQ = () => {
  const faqs = [
    {
      question: "How do I book an appointment?",
      answer: "The clinic currently does not offer online booking. Please call or text us at 778-956-9061 to schedule your first appointment. Follow-up appointments can be booked while you're at the clinic."
    },
    {
      question: "What are your payment methods?",
      answer: "We accept Interac e-transfer to jmrehabclinic@gmail.com and cash payments. For insurance claims, we offer direct billing with most major insurers."
    },
    {
      question: "What is your cancellation policy?",
      answer: "If you cannot make it to your appointment, please call or text us in advance. A $20 fee is charged for missed appointments from the second occurrence onwards."
    },
    {
      question: "Do you offer direct billing?",
      answer: "Yes, we provide direct billing with most major insurance providers. Please note that any amount not covered by your insurer will be your responsibility."
    },
    {
      question: "What should I bring to my first appointment?",
      answer: "Please bring your insurance information (if applicable) and arrive 10-15 minutes early to complete necessary paperwork. For subsequent visits, please arrive 3-5 minutes before your scheduled time."
    },
    {
      question: "What are your COVID-19/Flu protocols?",
      answer: "We recommend wearing a medical mask during your visit. Hand sanitization is required upon arrival, and we maintain strict cleaning protocols between patients. A COVID-19 screening form must be completed before your appointment. If you are feeling unwell, please contact us to reschedule your appointment."
    },
    {
      question: "What types of treatments do you offer?",
      answer: "We offer both Physiotherapy (including LASER, Ultrasound, IFC/TENS, and Functional dry needling) and Massage Therapy services (including Myofascial Release, Trigger Point Therapy, and Hot/Cold Stone Massage)."
    },
    {
      question: "Where is the clinic located and where can I park?",
      answer: "We are located at Unit D, 6225 - 136 Street, Surrey, BC V3X 1H3. Access to the clinic is from the back of the building with ample parking space available. Call us if you need additional parking instructions."
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  return (
    <>
      <Helmet>
        <title>FAQ - JM Rehab Clinic | Surrey, BC</title>
        <meta name="description" content="Frequently asked questions about our physiotherapy and massage therapy services, policies, and procedures." />
      </Helmet>
      
      <div className="pt-24 pb-12 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-12">Frequently Asked Questions</h1>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div 
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden"
              >
                <button
                  className="w-full text-left px-6 py-4 flex justify-between items-center hover:bg-gray-50"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                >
                  <span className="font-medium text-gray-900">{faq.question}</span>
                  {openIndex === index ? (
                    <ChevronUpIcon className="h-5 w-5 text-sage-green" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-sage-green" />
                  )}
                </button>
                {openIndex === index && (
                  <div className="px-6 py-4 bg-gray-50">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Contact Call-to-Action */}
          <div className="mt-12 text-center">
            <p className="text-gray-600 mb-4">
              Still have questions? We're here to help!
            </p>
            <div className="space-y-2">
              <p className="font-medium">Call or text: +1 778-956-9061</p>
              <p className="font-medium">Email: jmrehabclinic@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;