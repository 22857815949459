import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  UserGroupIcon,
  HeartIcon,
  AcademicCapIcon,
  SparklesIcon,
  BoltIcon,
  HandThumbUpIcon,
  StarIcon,
  FingerPrintIcon
} from '@heroicons/react/24/outline';

function Home() {
  // Services data
  const services = [
    {
      title: 'Physiotherapy',
      description: 'Patient centered care for injury recovery, chronic pain management, and rehabilitation.',
      icon: HeartIcon,
      image: '/images/services/physio.jpg'
    },
    {
      title: 'Physio-assisted Active Rehab',
      description: 'Personalized rehabilitation programs to help you regain strength and mobility.',
      icon: BoltIcon,
      image: '/images/services/rehab.jpg'
    },
    {
      title: 'IMS/Dry Needling',
      description: 'Targeted treatment for muscle pain and tension using specialized techniques.',
      icon: SparklesIcon,
      image: '/images/services/needle.jpg'
    },
    {
      title: 'Vestibular & Concussion Therapy',
      description: 'Specialized care for balance disorders and concussion recovery.',
      icon: UserGroupIcon,
      image: '/images/services/concussion.jpg'
    },
    {
      title: 'Registered Massage Therapy',
      description: 'Professional massage therapy for relaxation and pain relief.',
      icon: HandThumbUpIcon,
      image: '/images/services/rmt.jpg'
    },
    {
      title: 'Personalized Care',
      description: 'Individual attention and customized treatment plans for optimal results.',
      icon: FingerPrintIcon,
      image: '/images/services/care.jpg'
    },
  ];

  const testimonials = [
    // {
    //   name: "Asha M",
    //   text: "I have been a patient of Jisha and Wren for nearly two years, and I highly recommend their services. Their expertise as a massage therapist and physiotherapist has greatly improved my health and well-being.From the beginning, Jisha took the time to understand my issues and created a treatment plan that suited my needs. Their techniques have helped relieve my pain, improve my movement, and make me feel much better overall. What stands out the most is their kindness and genuine care for their patients. They always make me feel comfortable and valued during every session. I am very grateful for their help and dedication. If you're looking for someone skilled and caring, Jisha and Wren are the perfect choice",
    //   rating: 5,
    //   profession: "Nurse",
    //   image: "/images/testimonials/test1.jpg"
    // },
    {
      name: "Gloria Geddert",
      text: "I highly recommend the services of JM Rehab Clinic. Wren and Jisha team is top notch. Wren helped me with fibromyalgia and knee replacement surgery. His treatments include intramuscular needling, laser, ultrasound, and TENS/IFC. Jisha's hot stone massage technique is wonderful for fibromyalgia. I recommend both practitioners without reservation.",
      rating: 5,
      profession: "Retired Unit Clerk",
      image: "/images/testimonials/test2.jpg"
    },
    {
      name: "Divya N",
      text: "I can't thank Jisha enough for her incredible care and insight. Her expertise and genuine concern led to a diagnosis of ankylosing spondylitis. Her thoughtful observation and amazing massage therapy have truly changed my life. Highly recommend her professional and caring approach.",
      rating: 5,
      profession: "Service Contract Administrator",
      image: "/images/testimonials/test3.jpg"
    },
    {
      name: "Tandeep K",
      text: "Finally found satisfaction with Jisha at JM clinic. She's very skilled and kindhearted. The tension in my lower back and shoulders is completely gone. Plus, having physiotherapy available with Wren is fantastic. My husband is very pleased with her post-op knee surgery therapy.",
      rating: 5,
      profession: "Registered Nurse",
      image: "/images/testimonials/test4.jpg"
    }
  ];

  return (
    <>
      <Helmet>
        <title>JM Rehab Clinic - Physiotherapy & Massage Therapy in Surrey, BC</title>
      </Helmet>

      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center justify-center">
        {/* Background Image */}
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url(/images/hero-bg.jpg)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="absolute inset-0 bg-black/40"></div>
        </div>
        
        {/* Content */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-white pt-20">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 drop-shadow-lg">
            Welcome to JM Rehab Clinic
          </h1>
          <p className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto drop-shadow-lg">
           Trusted physiotherapy and massage therapy in Surrey, BC
          </p>
          <Link
            to="/contact"
            className="bg-sage-green hover:bg-sage-green/90 text-white px-8 py-3 rounded-md text-lg font-medium transition-colors"
          >
            Book an Appointment
          </Link>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Our Services
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We offer a comprehensive range of rehabilitation and therapy services
              to help you achieve optimal health and wellness.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow flex flex-col items-center text-center"
              >
                <div className="relative w-24 h-24 mb-6">
                  {service.image && (
                    <img
                      src={service.image}
                      alt={service.title}
                      className="w-full h-full object-cover rounded-full"
                      loading="lazy"
                      width={96}
                      height={96}
                    />
                  )}
                </div>
                <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-600 max-w-sm">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section className="relative h-[400px] bg-fixed bg-center bg-no-repeat bg-cover"
        style={{ 
          backgroundImage: 'url(/images/hero-bg1.jpg)',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-center">
          <div className="text-center text-white">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Experienced Patient Centered Care</h2>
            <p className="text-xl max-w-2xl mx-auto">
              Our dedicated team of professionals is committed to helping you achieve optimal health and well-being
            </p>
          </div>
        </div>
      </section>

      {/* Contact/CTA Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="space-y-8">
              <div>
                <h2 className="text-3xl font-bold text-gray-900 mb-4">Visit Our Clinic</h2>
                <p className="text-lg text-gray-600 mb-6">
                  We're conveniently located in Surrey, BC with ample parking space at the back of the building.
                </p>
              </div>

              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-sage-green" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">Address</h3>
                    <p className="mt-1 text-gray-600">Unit D, 6225 - 136 Street</p>
                    <p className="text-gray-600">Surrey, BC V3X 1H3</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-sage-green" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">Contact</h3>
                    <p className="mt-1 text-gray-600">Office: <a href="tel:604-503-9955" className="text-sage-green hover:text-teal-700">604-503-9955</a></p>
                    <p className="text-gray-600">Cell: <a href="tel:778-956-9061" className="text-sage-green hover:text-teal-700">778-956-9061</a></p>
                  </div>
                </div>
              </div>

              <div className="inline-flex space-x-4">
                <Link
                  to="/contact"
                  className="bg-sky-blue text-white px-6 py-3 rounded-md hover:bg-slate-blue transition-colors"
                >
                  Book Now
                </Link>
                <a
                  href="https://maps.google.com/?q=JM+Rehab+Clinic+Surrey+BC"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gray-100 text-gray-800 px-6 py-3 rounded-md hover:bg-gray-200 transition-colors"
                >
                  Get Directions
                </a>
              </div>
            </div>

            <div className="h-[400px] rounded-lg overflow-hidden shadow-lg">
              <iframe
                title="JM Rehab Clinic Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2608.0935493164584!2d-122.85118812342752!3d49.13095097931543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d9c28ae3fd1d%3A0x79dd9b6e6c0f505f!2s6225%20136%20St%20Unit%20D%2C%20Surrey%2C%20BC%20V3X%201H3!5e0!3m2!1sen!2sca!4v1710901234567!5m2!1sen!2sca"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-gray-50 relative">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{ backgroundImage: 'url(/images/spa_room2.jpg)' }}
        ></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              What Our Patients Say
            </h2>
            <div className="flex items-center justify-center gap-2 mb-4">
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <StarIcon 
                    key={i} 
                    className="h-8 w-8 text-yellow-300 fill-current"
                  />
                ))}
              </div>
              <span className="text-2xl font-bold text-gray-900">5.0</span>
              <a 
                href="https://maps.google.com/?q=JM+Rehab+Clinic+Surrey+BC" 
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-sage-green hover:underline transition-colors"
              >
                ({17} Google reviews)
              </a>
            </div>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Read about the experiences of our valued patients and their journey to recovery with JM Rehab Clinic.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
              >
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.image || '/images/testimonials/default-avatar.jpg'}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover mr-4"
                    loading="lazy"
                  />
                  <div>
                    <p className="font-semibold text-gray-900">{testimonial.name}</p>
                    <p className="text-sm text-gray-500">{testimonial.profession}</p>
                  </div>
                </div>
                <div className="flex mb-4">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <StarIcon key={i} className="h-5 w-5 text-yellow-300 fill-current" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Home; 