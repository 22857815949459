import { Helmet } from 'react-helmet-async';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const DirectBilling = () => {
  const benefits = [
    {
      title: "Convenience",
      description: "Patients don't need to handle paperwork or manage claims with their insurance providers. This saves time and reduces stress."
    },
    {
      title: "Immediate Coverage",
      description: "With direct billing, patients can receive treatment/rehab services without worrying about out-of-pocket expenses at the time of treatment, as their insurance is billed directly."
    },
    {
      title: "Transparency",
      description: "Patients can easily understand what services are covered by their insurance and what their financial responsibilities are before treatment."
    },
    {
      title: "Improved Access",
      description: "By simplifying the payment process, direct billing can encourage more individuals to seek rehabilitation services."
    }
  ];

  const insurers = [
    "BPA", "Alberta Blue cross", "Canada life", "Canadian construction workers union",
    "Chamber of commerce", "CINUP", "Claim secure", "Coughlin & associates",
    "Cowan insurance", "DA Townley", "Desjardins", "Equitable life",
    "First Canadian", "GMS carrier 49", "GMS Carrier 50", "Green shield Canada",
    "Group health", "Group source", "Industrial alliance",
    "Johnson INC", "Johnston group", "LA capitale financial group", "LiUna local 183",
    "LiUna Local 506", "Manion", "Manulife", "Maximum Benefit",
    "MSP", "Pacific Blue Cross", "People corporation", "PSHCP Canada life",
    "RWAM insurance administrators", "Sunlife", "Telus Adjudi care", "Union benefits",
    "UV insurance", "Wawanesa life", "WSBC"
  ];

  return (
    <>
      <Helmet>
        <title>Direct Billing - JM Rehab Clinic | Surrey, BC</title>
        <meta name="description" content="Direct billing available with major insurance providers. Learn about our billing policies and coverage options." />
      </Helmet>

      <div className="pt-24 pb-12 bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-12">Direct Billing & Insurance</h1>

          {/* Benefits Section */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-2xl font-semibold mb-6">Benefits of Direct Billing</h2>
            <div className="grid sm:grid-cols-2 gap-6">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-start">
                  <CheckCircleIcon className="h-6 w-6 text-sage-green flex-shrink-0 mt-1 mr-3" />
                  <div>
                    <h3 className="font-medium text-gray-900 mb-1">{benefit.title}</h3>
                    <p className="text-gray-600">{benefit.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* How It Works Section */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-2xl font-semibold mb-6">How It Works</h2>
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="bg-sage-green/10 rounded-full p-2 mr-3">
                  <span className="text-sage-green font-semibold">1</span>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 mb-1">Insurance Verification</h3>
                  <p className="text-gray-600">It is the patient's responsibility to verify their insurance coverage before the appointment. The clinic cannot confirm eligibility on behalf of the patient. However, some insurers allow patients to check their coverage through their online portals.</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="bg-sage-green/10 rounded-full p-2 mr-3">
                  <span className="text-sage-green font-semibold">2</span>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 mb-1">Service Delivery</h3>
                  <p className="text-gray-600">After the massage therapy session, the clinic directly bills the insurance company for the services rendered.</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="bg-sage-green/10 rounded-full p-2 mr-3">
                  <span className="text-sage-green font-semibold">3</span>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 mb-1">Patient Responsibility</h3>
                  <p className="text-gray-600">Please make sure that the treatments are covered by your insurance plan prior to the visit. If there are any fees not covered by the insurance (like deductibles or co-pays), patients are informed of their financial responsibilities and those charges are incurred on the patient. The clinic does not take responsibility to call your insurer for non-payments.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Insurers List */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-2xl font-semibold mb-6">Our Integrated Insurers</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {insurers.map((insurer, index) => (
                <div key={index} className="flex items-center">
                  <CheckCircleIcon className="h-5 w-5 text-sage-green mr-2" />
                  <span className="text-gray-600">{insurer}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Important Notice */}
          <div className="bg-yellow-50 rounded-lg p-6">
            <div className="flex items-start">
              <ExclamationCircleIcon className="h-6 w-6 text-yellow-600 mr-3 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium text-yellow-800 mb-2">Important Notice</h3>
                <p className="text-yellow-700">
                  The clinic has adopted a strict policy of not giving out invoices which means patients are not able to submit to insurers on this clinic's behalf. However, if it's an insurer with which JM rehab clinic has not integrated for direct billing, the clinic would provide an invoice once after the payment has been e-transferred to jmrehabclinic@gmail.com
                </p>
                <p className="mt-4 text-yellow-700 font-medium">
                  If you have further questions on billing, please contact the clinic by phone or email.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectBilling; 