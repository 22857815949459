import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PhoneIcon, ChatBubbleLeftIcon, BuildingOffice2Icon } from '@heroicons/react/24/outline';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form submitted:', formData);
  };

  const handleWhatsApp = () => {
    const message = encodeURIComponent("Hello, I would like to book an appointment at JM Rehab Clinic.");
    window.open(`https://wa.me/17789569061?text=${message}`, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Book an Appointment - JM Rehab Clinic</title>
      </Helmet>

      <div className="min-h-screen bg-gray-50 pt-24 pb-12 relative">
        {/* Background Image */}
        <div 
          className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{ 
            backgroundImage: "url('/images/spa_room2.jpg')", 
          }}
        />
        
        {/* Content Container */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 sm:mb-12">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-3 sm:mb-4">
              Book an Appointment
            </h1>
            <p className="text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto px-4">
              Choose your preferred way to book an appointment with us
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
            {/* Contact Form */}
            <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">Contact Form</h2>
              <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sage-green focus:ring-sage-green"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sage-green focus:ring-sage-green"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sage-green focus:ring-sage-green"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="service" className="block text-sm font-medium text-gray-700">
                    Service
                  </label>
                  <select
                    id="service"
                    value={formData.service}
                    onChange={(e) => setFormData({ ...formData, service: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sage-green focus:ring-sage-green"
                    required
                  >
                    <option value="">Select a service</option>
                    <option value="physiotherapy">Physiotherapy</option>
                    <option value="rehab">Physio-assisted Active Rehab</option>
                    <option value="ims">IMS/Dry Needling</option>
                    <option value="vestibular">Vestibular & Concussion Therapy</option>
                    <option value="massage">Registered Massage Therapy</option>
                    <option value="shoulder">Shoulder Assessment</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sage-green focus:ring-sage-green"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-sage-green hover:bg-sage-green/90 text-white py-2 px-4 rounded-md transition-colors"
                >
                  Submit Request
                </button>
              </form>
            </div>

            {/* WhatsApp and Contact Info */}
            <div className="space-y-4 sm:space-y-6">
              <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">Message on WhatsApp</h2>
                <p className="text-gray-600 mb-6">
                  Prefer to chat? Send us a message on WhatsApp for quick responses.
                </p>
                <button
                  onClick={handleWhatsApp}
                  className="w-full bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md transition-colors flex items-center justify-center gap-2"
                >
                  <ChatBubbleLeftIcon className="h-5 w-5" />
                  Chat on WhatsApp
                </button>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-semibold mb-6">Contact Information</h2>
                <div className="space-y-4">
                  <div className="flex items-center gap-4">
                    
                    <div>
                      <p className="font-medium flex items-center gap-2">
                        <PhoneIcon className="h-5 w-5 text-sage-green" />
                        Phone Numbers
                      </p>
                      <p className="text-gray-600">Office: <a href="tel:604-503-9955" className="text-sage-green hover:underline">604-503-9955</a></p>
                      <p className="text-gray-600">Cell: <a href="tel:778-956-9061" className="text-sage-green hover:underline">778-956-9061</a></p>
                    </div>
                  </div>
                  <div>
                    <p className="font-medium flex items-center gap-2">
                        <BuildingOffice2Icon className="h-5 w-5 text-sage-green" />
                        Address
                      </p>
                    <p className="text-gray-600">Unit D, 6225 - 136 Street</p>
                    <p className="text-gray-600">Surrey, BC V3X 1H3</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;