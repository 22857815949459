import { Helmet } from 'react-helmet-async';

const Gallery = () => {
  const galleryImages = [
    // Exterior and Entry Images
    {
      id: 25,
      src: "images/gallery/building.jpg",
      alt: "JM Rehab Clinic Building",
      className: "md:col-span-2 md:row-span-2" // Hero image - Building exterior
    },
    {
      id: 26,
      src: "images/gallery/parking1.jpg",
      alt: "Front Parking Area",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 27,
      src: "images/gallery/parking2.jpg",
      alt: "Additional Parking Space",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 28,
      src: "images/gallery/entrance.jpg",
      alt: "Clinic Entrance",
      className: "md:col-span-2 md:row-span-1"
    },
    // Reception and Waiting Area
    {
      id: 1,
      src: "images/gallery/reception.jpg",
      alt: "Welcoming Reception Area",
      className: "md:col-span-2 md:row-span-2"
    },
    {
      id: 10,
      src: "images/massage3.jpg",
      alt: "Patient Centered Care",
      className: "md:col-span-1 md:row-span-1"
    },
    // Treatment Rooms
    {
      id: 2,
      src: "images/gallery/room1.jpg",
      alt: "Treatment Room 1",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 3,
      src: "images/gallery/room2.jpg",
      alt: "Treatment Room 2",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 17,
      src: "images/gallery/room3.jpg",
      alt: "Treatment Room 3",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 16,
      src: "images/gallery/spa_room.jpg",
      alt: "Bright & Spacious Treament Facility",
      className: "md:col-span-2 md:row-span-1"
    },
    // Treatment Areas and Equipment
    {
      id: 6,
      src: "images/gallery/exercise.jpg",
      alt: "Exercise and Rehabilitation Area",
      className: "md:col-span-2 md:row-span-2"
    },
    // Treatment Types and Services
    {
      id: 15,
      src: "images/services/physio.jpg",
      alt: "Professional Physiotherapy",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 22,
      src: "images/services/rmt.jpg",
      alt: "Registered Massage Therapy",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 23,
      src: "images/services/needle.jpg",
      alt: "IMS/Dry Needling Treatment",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 21,
      src: "images/services/rehab.jpg",
      alt: "Active Rehab",
      className: "md:col-span-1 md:row-span-1"
    },
    // Amenities and Details
    {
      id: 11,
      src: "images/services/care.jpg",
      alt: "Organic Coconut Oil",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 18,
      src: "images/gallery/hotstone.jpg",
      alt: "Hot/Cold Stone",
      className: "md:col-span-1 md:row-span-1"
    },
    {
      id: 19,
      src: "images/spa_room3.jpg",
      alt: "Clean and fresh linen",
      className: "md:col-span-2 md:row-span-1"
    }
  ];

  return (
    <>
      <Helmet>
        <title>Gallery - JM Rehab Clinic | Surrey, BC</title>
        <meta name="description" content="Take a virtual tour of JM Rehab Clinic's modern facilities, from our convenient parking to our well-equipped treatment rooms in Surrey, BC." />
      </Helmet>

      <div className="pt-24 pb-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-6">Clinic Gallery</h1>
          
          <div className="text-center max-w-2xl mx-auto mb-12">
            <p className="text-gray-600 text-lg">
              Take a virtual tour of our modern facility, from our convenient parking to our well-equipped treatment rooms. 
              Our clinic provides a comfortable and professional environment for your rehabilitation journey.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 auto-rows-[200px]">
            {galleryImages.map((image) => (
              <div 
                key={image.id} 
                className={`relative group overflow-hidden rounded-lg shadow-md ${image.className}`}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-opacity duration-300 flex items-end">
                  <p className="text-white p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-medium">
                    {image.alt}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery; 