import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import Policies from './pages/Policies';
import Gallery from './pages/Gallery';
import DirectBilling from './pages/DirectBilling';
function App() {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/policies" element={<Policies />} /> 
            <Route path="/direct-billing" element={<DirectBilling />} />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App; 